import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import './App.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChild, faFlagCheckered, faMale, faStar } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';


function App() {
  const [text, setText] = useState('Hej!');

  useEffect(() => {
    setTimeout(() => setText('Välkommen till ' + (document.location.href.indexOf('skolan') > 0 ? 'innebandyskolan.' : 'innebandylekis.')), 3000);
    setTimeout(() => setText('Hur en innebandyresa i IBF Tranås kan se ut läser du mer om här.'), 6000);

  },[]);


  return (
    <div className="App">
      <header className="App-header">
        <Navbar bg="white" expand={false}>
          <Container fluid>
            <Navbar.Brand href="#"><img style={{ 'filter': 'invert(1)', 'height': '50px' }} src="http://innebandylekis.se/Content/Images/logo_transp_white.png" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" />
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="http://www.ibf-tranas.com/">Nyheter IBF</Nav.Link>
                  <Nav.Link href="http://www.ibf-tranas.com/sida/?ID=43620">Klubbinfo</Nav.Link>
                  <Nav.Link href="http://www.ibf-tranas.com/sida/?ID=43624">Deltagaravgifter</Nav.Link>
                  <Nav.Link href="http://www.ibf-tranas.com/sektion/?ID=43364">Våra lag</Nav.Link>
                  <Nav.Link href="http://www.ibf-tranas.com/sida/?ID=43625">Träningstider</Nav.Link>
                  <Nav.Link href="http://www.ibf-tranas.com/sida/?ID=43623">Kontakt</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </header>


        <div className='hello-outer mb-5'>
          <div className="hello">
            <img src="IBF_Panda_text.png" />
            <div className='message'>{text}</div>
          </div>
           
        </div>

        <VerticalTimeline lineColor='black'>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date=""
            contentStyle={{ background: '#D2CBD2', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid #D2CBD2' }}
            iconStyle={{ background: '#D2CBD2', color: 'white' }}

            icon={<FontAwesomeIcon icon={faStar} size='lg' />}
          >
            <h3 className="vertical-timeline-element-title">RESAN BÖRJAR HÄR</h3>
            <p style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
              Framtidens innebandyspelare inleder och avslutar sina karriärer i IBF Tranås, 
              vi brukar säga att vi ”lånar” barn och ungdomar under kort eller lång tid i vår verksamhet. 
              Innebandylekis 4-5 år kan vara en första kontakt med en lagidrott. Hos oss är det mötet alltid lekfullt.
               Fortsätter man lira i IBF Tranås väntar Innebandyskolan 6-7 år, från 8 år poolspel och därefter seriespel. 
               Vi har alltid som målsättning att skapa egna träningsgrupper för pojkar och flickor från 4 år. Vi tränar en gång i veckan - totalt ungefär tio tillfällen under ”hösten” och lika många under ”våren”. Det ska vara enkelt, billigt och säkert att spela innebandy i IBF Tranås. Därför gäller deltagaravgiften för hela säsongen, olycksfallsförsäkring ingår och vi använder skyddsglasögon.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="4-5 ÅR"
            contentStyle={{ background: '#F97B62', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid #F97B62' }}
            iconStyle={{ background: '#F97B62', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faChild} fixedWidth />}
          >
            <h3 className="vertical-timeline-element-title">INNEBANDYLEKIS</h3>
            <p>
              Här blandar vi lekövningar med stafetter och nöter enkla passnings- och teknikövningar. Vi introducerar också matchspel för barnen. Som förälder finns du med i bakgrunden, på läktaren - inte mer än så. Vi börjar nu också att lägga vikt vid ett lekfullt ordning och reda där man "ställer sig sist i ledet" och försiktigt förstå att innebandy är ett lagspel.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="6-7 ÅR"
            contentStyle={{ background: '#FE08A9', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid #FE08A9' }}
            iconStyle={{ background: '#FE08A9', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faMale} size="lg" fixedWidth />}
          >
            <h3 className="vertical-timeline-element-title">INNEBANDYSKOLAN </h3>
            <p>
              Nu börjar innebandyn att ta över träningen helt. Vi gör oss sakta men säkert redo för poolspel i pojk- och flicklag.

            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="8 ÅR"
            contentStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid rgb(16, 204, 82)' }}
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            icon={<FontAwesomeIcon icon={faFlagCheckered} fixedWidth />}
          >
            <h3 className="vertical-timeline-element-title">UNGDOMSLAG</h3>
            <p>
            Nu är vi redo att ta steget in i en värld som består av både träning och match. Vi spelar till att börja med matcher i en form som kallas poolspel. Det gör vi ungefär en gång i månaden. Laget spelar då i regel tre korta matcher vid ett och samma tillfälle. Spelplanen är hälften så stor och målburarna är också mindre. Vi är tre utespelare och en målvakt på planen samtidigt.
            </p>
          </VerticalTimelineElement>

        </VerticalTimeline>
        <div className='text-center my-5'>
          <a href="https://ibf-tranas.com" className='btn btn-dark'>Gå vidare till IBF Tranås</a>
        </div>
        <svg className="svg">
            <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox"><path d="M0.5,0.045 C0.236,0.045,0,0.238,0,0.5 C0,0.594,0.031,0.685,0.085,0.756 L0.002,1 L0.292,0.909 C0.363,0.929,0.431,0.938,0.494,0.938 C0.793,0.938,1,0.736,1,0.5 C1,0.236,0.762,0.045,0.5,0.045"></path></clipPath>

          </svg>

   
    </div>
    
  );
}

export default App;
